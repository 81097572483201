import React, { FC } from 'react';

import './Video.scss';
import { VideoComponent } from 'types';

export const Video: FC<VideoComponent> = ({ videoId, componentTitle, title, text, linkUrl }) => {
  return (
    <div data-component="video">
      {componentTitle && <div className="section-label" dangerouslySetInnerHTML={{ __html: componentTitle }} />}

      <div className="video-order">
        <div className="video-box">
          <div className="video">
            <iframe
              src={`//www.youtube.com/embed/${videoId}?controls=1&rel=0&showinfo=0&modestbranding=0`}
              className="yt-video-embed"
              allowFullScreen
              frameBorder="0"
            />
          </div>
        </div>

        {(title || text) && (
          <div className="video-caption">
            {title && <div className="caption-title" dangerouslySetInnerHTML={{ __html: title }} />}
            {text && <div className="caption-description" dangerouslySetInnerHTML={{ __html: text }} />}

            {linkUrl && (
              <a href={linkUrl} className="btn" target="_blank">
                More &nbsp; <i className="fal fa-chevron-right" />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
