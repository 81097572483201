// import Author from "classes/author.class";
import Link from 'next/link';
import React, { FC } from 'react';

import './Profile.scss';

interface ProfileProps {
  author: any;
  // author: Author;
}

export const Profile: FC<ProfileProps> = ({ author }) => {
  const isDefault = !author.profileImage;
  const profileImage = author.profileImage || '/static/img/platform-corner-logo-white.png';

  return (
    <div data-component="profile">
      <div className="profile-box">
        <Link href={`/author/${author.slug}`}>
          <a className="box-top" style={{ backgroundImage: `url("${author.backgroundImage}")` }} />
        </Link>
        <div className="box-bottom">
          <Link href={`/author/${author.slug}`}>
            <a className="box-profile" data-default={isDefault}>
              <img src={profileImage} alt={author.name} />
            </a>
          </Link>
          <div className="box-details">
            <h1 dangerouslySetInnerHTML={{ __html: author.name }} />
            {author.twitter && (
              <a className="profile-twitter" target="_blank" href={`https://twitter.com/${author.twitter}`}>
                <i className="fab fa-twitter" /> @{author.twitter}
              </a>
            )}
            <p dangerouslySetInnerHTML={{ __html: author.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};
