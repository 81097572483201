import './Promos.scss';
import Link from 'next/link';

import React, { FC } from 'react';
import { Backgroundify } from 'Services/helper.service';

// import Article from '../../../definitions/classes/article.class';

interface PromosComponentProps {
  articles: any[];
  label?: string;
  isFormatted?: Boolean;
}

export const Promos: FC<PromosComponentProps> = ({ articles, label, isFormatted = false }) => {
  return (
    <>
      {label && <div className="section-label" dangerouslySetInnerHTML={{ __html: label }} />}

      <div data-component="promos">
        {articles.map((article, index) => (
          <Link href={`/posts/[articleSlug]}`} as={`/posts/${article.slug}`} key={article.slug}>
            <a className="promo">
              <div className="item-image" style={Backgroundify(article.featuredImage?.url)} />
              <div className="item-overlay">
                <div className="overlay-author" dangerouslySetInnerHTML={{ __html: article.author?.name }} />
                <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
              </div>
            </a>
          </Link>
        ))}
      </div>
    </>
  );
};
