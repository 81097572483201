import React, { FC } from 'react';
import Link from 'next/link';

import { Backgroundify } from 'Services/helper.service';
import './Hashes.scss';
import { TagComponent } from 'types';

export const Hashes: FC<TagComponent> = ({ tag, maxItems = 4, articles }) => {
  return (
    <div data-component="hashes" data-visible={!!articles.length}>
      <div className="hash-tag">
        <i className="fal fa-hashtag" /> {tag.name}
      </div>

      <div className="hash-stories">
        {articles.map(article => (
          <Link href={`/posts/${article.slug}`} key={article.slug}>
            <a className="hash">
              <div className="item-image" style={Backgroundify(article.featuredImage?.url)} />
              <div className="item-overlay">
                <div className="overlay-author">{article.author.name}</div>
                <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};
