import { gql } from '@apollo/client';

export const GET_CATEGORY = gql`
  query getCategory($categorySlug: String!) {
    category(categorySlug: $categorySlug) {
      description
      id
      name
      slug
    }
  }
`;
