import './Newsletter.scss';

import React, { FC, useMemo, useState } from 'react';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

import useSWR from 'swr';

interface NewsletterProps {
  title?: string;
  text?: string;
}

enum statuses {
  default = 'default',
  success = 'success',
  error = 'error',
}

const url = 'https://platformonline.us20.list-manage.com/subscribe/post-json?u=f5b9b0ce6e20f3e06993f345b&id=b34bfe4ad4';

export const Newsletter: FC<NewsletterProps> = ({ title = 'Keep up with the latest articles', text }) => {
  const [email, setEmail] = useState('');
  const [payload, setPayload] = useState<string | null>(null);

  const { data, error } = useSWR(payload && [url, payload], async (url: string, email: string) => {
    // @ts-ignore
    return axios({
      url: `${url}&EMAIL=${email}`,
      adapter: jsonpAdapter,
      callbackParamName: 'c',
    });
  });

  const response = data?.data;

  const subscribeRequest = async () => {
    // subscribe();
    if (!email) {
      setPayload(null);
      return;
    }

    setPayload(email);
  };

  const isStatus = (_status: string): boolean => _status === status;

  return (
    <div data-component="newsletter">
      <div className="newsletter" data-visible={!response}>
        <div className="newsletter-title" dangerouslySetInnerHTML={{ __html: title }} />

        <div className="newsletter-text" dangerouslySetInnerHTML={{ __html: text ?? '' }} />

        <input
          type="email"
          className="newsletter-input"
          placeholder="email@example.com"
          autoComplete="off"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <div className="newsletter-button" onClick={subscribeRequest}>
          Submit
        </div>
      </div>

      <div className="newsletter thank-you" data-visible={response?.result === 'error'}>
        <div className="newsletter-title">Looks like there was an issue</div>
        <div className="newsletter-text" dangerouslySetInnerHTML={{ __html: response?.msg }} />

        <input
          type="email"
          className="newsletter-input"
          placeholder="email@example.com"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <div className="newsletter-button" onClick={subscribeRequest}>
          Submit
        </div>
      </div>

      <div className="newsletter thank-you" data-visible={response?.result === 'success'}>
        <div className="newsletter-title">Success</div>
        <div className="newsletter-text">
          Thank you for subscribing to The Platform, please check your email for confirmation.
        </div>
      </div>
    </div>
  );
};
