import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';

import { isAtTopAtom } from 'Atoms';

const useScrollListener = () => {
  const setAtTop = useUpdateAtom(isAtTopAtom);

  useEffect(() => {
    if (!process.browser) return;

    window.addEventListener('wheel', e => {
      if (e.deltaY < 1) setAtTop(true);
      if (e.deltaY > -1) setAtTop(false);
    });
  }, []);
};

export { useScrollListener };
