import { gql } from '@apollo/client';

export const GET_PAGE = gql`
  query getPage($id: ID, $slug: String) {
    page(id: $id, slug: $slug) {
      id
      date
      title
      slug
      type
      status
      featuredImage {
        id
        title
        caption
        url
        largeUrl
        webp
        jpg
      }
      author {
        id
        slug
        name
        profileImage
      }
      content
      excerpt
      summary
      components
      componentData {
        acf_fc_layout: __typename

        ... on HeroComponent {
          options
          mainArticle {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
          sideArticles {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
        }

        ... on TagComponent {
          options
          maxItems
          tag {
            id
            slug
            name
          }
          articles {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
        }

        ... on NewsletterComponent {
          options
          componentTitle
          text
          title
        }

        ... on TextComponent {
          options
          text
          title
        }

        ... on ImageComponent {
          options
          caption
          image {
            id
            title
            caption
            url
            jpg
            webp
            largeUrl
          }
        }

        ... on RandomArticlesComponent {
          maxArticles
          options
          articles {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
        }

        ... on LatestPostsComponent {
          maxPosts
          options
          articles {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
        }

        ... on FeatureComponent {
          options
          article {
            id
            title
            excerpt
            summary
            slug
            featuredImage {
              url
            }
            categories {
              slug
            }
            author {
              name
              slug
            }
          }
        }

        ... on VideoComponent {
          options
          componentTitle
          text
          title
          videoId
          linkUrl
        }
      }
    }
  }
`;
