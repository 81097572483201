import { useAnalytics } from './useAnalytics';
import { useFetchGeneralOptions } from './useFetchGeneralOptions';
import { useScrollListener } from './useScrollListener';

const useAppInitialise = () => {
  useFetchGeneralOptions();
  useAnalytics();
  useScrollListener();
};

export { useAppInitialise };
