import { gql } from '@apollo/client';

export const GET_TAG = gql`
  query getCategory($tagSlug: String!) {
    tag(tagSlug: $tagSlug) {
      description
      id
      name
      slug
    }
  }
`;
