import { useQuery } from '@apollo/client';
import Link from 'next/link';
import { GET_ARTICLE } from 'Queries';

import React, { FC } from 'react';
import { Backgroundify } from 'Services/helper.service';

import './Feature.scss';
import { FeatureComponent } from 'types';

export const Feature: FC<FeatureComponent> = ({ article }) => {
  return (
    <div data-component="feature" data-visible={!!article}>
      {article && (
        <Link href={`/posts/${article.slug}`}>
          <a className="feature">
            <div className="item-overlay">
              <div className="overlay-author" dangerouslySetInnerHTML={{ __html: article.author.name }} />
              <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
            </div>
            <div className="item-image" style={Backgroundify(article.featuredImage?.url)} />
          </a>
        </Link>
      )}
    </div>
  );
};
