import './Randoms.scss';
import Image from 'next/image';

import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import Link from 'next/link';

import { GET_ARTICLES } from 'Queries';
import { RandomArticlesComponent } from 'types';

interface RandomsProps {
  label?: string;
  maxArticles?: number;
  article?: any;
  tags?: any[];
}
export const Randoms: FC<Partial<RandomArticlesComponent> & RandomsProps> = ({
  label,
  maxArticles = 3,
  tags = [],
  article,
  articles: _articles,
}) => {
  const { loading, error, data } = useQuery(GET_ARTICLES, {
    variables: {
      perPage: Number(maxArticles),
      tagSlugs: tags.map(tag => tag.slug),
    },
    skip: !!_articles,
  });
  const articles = _articles ?? (data?.articles ?? []).filter(item => item.id !== article?.id);

  return (
    <>
      {label && <div className="section-label" dangerouslySetInnerHTML={{ __html: label }} />}

      <div data-component="randoms" data-visible={!!articles.length}>
        {articles.map(article => (
          <Link href={`/posts/[articleSlug]`} as={`/posts/${article.slug}`} key={article.slug}>
            <a className="random">
              <Image
                src={article.featuredImage?.url || '/static/img/logo.png'}
                className="item-image"
                height={350}
                width={500}
              />
              <div className="item-overlay">
                <div className="overlay-author" dangerouslySetInnerHTML={{ __html: article.author?.name }} />
                <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
              </div>
            </a>
          </Link>
        ))}
      </div>
    </>
  );
};
