import React, { FC, useState } from 'react';
import Link from 'next/link';

import './Latest.scss';
import { LatestPostsComponent } from 'types';

export const Latest: FC<LatestPostsComponent> = ({ maxPosts = 10, articles }) => {
  const [currentItem, setCurrentItem] = useState(1);

  const moveLeft = () => {
    const newPos = currentItem - 1 < 0 ? 0 : currentItem - 1;
    setCurrentItem(newPos);
  };

  const moveRight = () => {
    const newPos = currentItem + 1 > articles.length - 1 ? articles.length - 1 : currentItem + 1;
    setCurrentItem(newPos);
  };

  const isLast = () => currentItem === articles.length - 1;
  const isFirst = () => currentItem === 0;
  const isCurrentItem = item => currentItem === item;

  if (!articles) return null;

  return (
    <div data-component="latest">
      <div className="latest-other">
        <div className="latest-title">Latest Articles</div>

        <div className="latest-left" onClick={moveLeft} data-inactive={isFirst()}>
          <i className="far fa-arrow-left" />
        </div>
        <div className="latest-right" onClick={moveRight} data-inactive={isLast()}>
          <i className="far fa-arrow-right" />
        </div>
      </div>

      <div className="latest" style={{ left: -320 * currentItem }}>
        {articles.map((article, index) => (
          <Link href={`/posts/${article.slug}`} key={article.slug}>
            <a className="latest-item" data-active={isCurrentItem(index)}>
              <picture>
                <source type="image/webp" srcSet={article.featuredImage?.url} />
                <img src={article.featuredImage?.url} className="item-image" />
              </picture>
              <div className="item-overlay">
                <div className="overlay-author" dangerouslySetInnerHTML={{ __html: article.author?.name }} />
                <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
                {/*<div className="overlay-stats">*/}
                {/*<div className="overlay-stat">*/}
                {/*<i className="fas fa-eye"/> 122*/}
                {/*</div>*/}
                {/*<div className="overlay-stat">*/}
                {/*<i className="fas fa-heart" style={{color: "#f08d8e"}}/> 66*/}
                {/*</div>*/}
                {/*<div className="overlay-stat">*/}
                {/*<i className="fas fa-comments-alt" style={{color: "#57c4ef"}}/> 7*/}
                {/*</div>*/}
                {/*</div>*/}
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};
