import './Quote.scss';

export const Quote = ({ quote, author }) => {
  return (
    <div data-component="quote">
      <div className="quote">
        <i className="fas fa-quote-left" />
        <i className="fas fa-quote-right" />

        <div dangerouslySetInnerHTML={{ __html: quote }} />

        {author && <div className="quote-author" dangerouslySetInnerHTML={{ __html: author }} />}
      </div>
    </div>
  );
};
