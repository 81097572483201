import React, { FC } from 'react';

import './Loader.component.scss';

const LoaderComponent: FC = () => {
  return (
    <div data-component="loader" data-visible="true">
      <i className="fal fa-spinner-third fa-spin" />
    </div>
  );
};

export { LoaderComponent };
