import { atom } from 'jotai';

const generalOptionsAtom = atom({
  footerLinks: [] as any[],
  socialLinks: [] as any[],
  headerPageLinks: [] as any[],
  menuPageLinks: [] as any[],
  menuCategories: [] as any[],
});

export { generalOptionsAtom };
