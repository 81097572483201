import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';

import { generalOptionsAtom } from 'Atoms';
import { API } from 'Server/Config.constants';

const useFetchGeneralOptions = () => {
  const setGeneralOptions = useUpdateAtom(generalOptionsAtom);

  useEffect(() => {
    fetch(`${API}/options?slug=general&_embed`).then(async res => {
      const generalOptions = (await res.json())[0].acf;
      setGeneralOptions(generalOptions);
    });
  }, []);
};

export { useFetchGeneralOptions };
