import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query getArticles(
    $ids: [ID]
    $slugs: [String]
    $tagSlug: String
    $categorySlug: String
    $tagSlugs: [String]
    $perPage: Int
    $page: Int
  ) {
    articles(
      ids: $ids
      slugs: $slugs
      tagSlug: $tagSlug
      categorySlug: $categorySlug
      tagSlugs: $tagSlugs
      perPage: $perPage
      page: $page
    ) {
      id
      date
      title
      slug
      type
      featuredImage {
        id
        title
        caption
        url
        largeUrl
        webp
        jpg
      }
      author {
        id
        slug
        name
        profileImage
        backgroundImage
        description
        twitter
      }
      tags {
        id
        slug
        name
        description
      }
      categories {
        id
        slug
        name
        description
      }
      content
      excerpt
      summary
      components
    }
  }
`;
