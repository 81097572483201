import Head from 'next/head';
import React from 'react';
import { SWRConfig } from 'swr';
import HttpsRedirect from 'react-https-redirect';
import { ApolloProvider } from '@apollo/client';
import { AnimateSharedLayout } from 'framer-motion';
import NextNprogress from 'nextjs-progressbar';

import { apolloClient } from 'Server/Gql/ApolloClient';
import { useAppInitialise } from 'Hooks';
import { Footer, Header, Sidebar } from 'Components';

import 'Global/app.scss';

function MyApp({ Component, pageProps }) {
  useAppInitialise();

  return (
    <>
      <NextNprogress color="#fff" startPosition={0.3} stopDelayMs={50} height={2} showOnShallow={true} />
      <Head>
        <meta name="robots" content="index, follow" />

        <link href="/static/platform-icon-512.png" rel="shortcut icon" type="image/x-icon" />

        <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css?family=Hind+Vadodara:300,400,500,600,700|Merriweather:300,400,400i,700,900&amp;subset=latin-ext"
          rel="stylesheet"
        />

        <link rel="shortcut icon" href="/static/platform-icon-192.png" />
        <link href="/static/lib/fontawesome/css/all.min.css" rel="stylesheet" />

        <meta content="yes" name="mobile-web-app-capable" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="#f3b520" name="apple-mobile-web-app-status-bar-style" />
        <meta content="#f3b520" name="theme-color" />

        <link href="/static/platform-icon-144.png" rel="apple-touch-icon" sizes="144x144" />
        <link href="/static/platform-icon-256.png" rel="apple-touch-icon" sizes="256x256" />
        <link href="/static/platform-icon-512.png" rel="apple-touch-icon" />
        <link href="/static/platform-icon-512.png" rel="apple-touch-startup-image" />
      </Head>
      <HttpsRedirect>
        <SWRConfig value={{ revalidateOnFocus: false }}>
          <ApolloProvider client={apolloClient}>
            <Header />
            <Sidebar />

            <AnimateSharedLayout>
              <Head>
                <meta content="width=device-width, user-scalable=no, viewport-fit=cover" name="viewport" />
              </Head>
              <Component {...pageProps} />
            </AnimateSharedLayout>
            <Footer />
          </ApolloProvider>
        </SWRConfig>
      </HttpsRedirect>
    </>
  );
}

export default MyApp;
