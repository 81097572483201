import { gql } from '@apollo/client';

export const GET_SEARCH = gql`
  query getSearch($search: String) {
    articles: search(search: $search) {
      id
      date
      title
      slug
      type
      featuredImage {
        id
        title
        caption
        url
        largeUrl
      }
      author {
        id
        slug
        name
        profileImage
      }
      content
      excerpt
      summary
      components
    }
  }
`;
