import { gql } from '@apollo/client';

export const GET_ARTICLE = gql`
  query getArticle($id: ID, $slug: String) {
    article(id: $id, slug: $slug) {
      id
      date
      title
      slug
      type
      featuredImage {
        id
        title
        caption
        url
        largeUrl
        webp
        jpg
      }
      author {
        id
        slug
        name
        profileImage
        backgroundImage
        description
        twitter
      }
      tags {
        id
        slug
        name
        description
      }
      categories {
        id
        slug
        name
        description
      }
      content
      excerpt
      summary
      components
    }
  }
`;
