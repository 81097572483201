import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Link from 'next/link';

import { GET_ARTICLE, GET_ARTICLES } from 'Queries';
import { Backgroundify } from 'Services/helper.service';

import './Hero.scss';
import { HeroComponent } from 'types';

export const Hero: FC<HeroComponent> = ({ mainArticle, sideArticles }) => {
  return (
    <div data-component="hero" data-visible={!!mainArticle}>
      <div className="hero">
        {mainArticle && (
          <Link href={`/posts/${mainArticle.slug}`} key={mainArticle.id}>
            <a className="hero-main">
              <Image src={mainArticle.featuredImage?.url} className="hero-image" layout="fill" />
              <div className="main-overlay">
                <div className="overlay-author" dangerouslySetInnerHTML={{ __html: mainArticle.author.name }} />
                <div className="overlay-title" dangerouslySetInnerHTML={{ __html: mainArticle.title }} />
              </div>
            </a>
          </Link>
        )}

        <div className="hero-items">
          {sideArticles?.map(article => (
            <Link href={`/posts/${article.slug}`} key={article.id}>
              <a className="hero-item">
                <div className="item-image" style={Backgroundify(article.featuredImage?.url)} />
                <div className="item-overlay">
                  <div className="overlay-author" dangerouslySetInnerHTML={{ __html: article.author.name }} />
                  <div className="overlay-title" dangerouslySetInnerHTML={{ __html: article.title }} />
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
