import './Text.scss';

export const Text = ({ text, page, isPage }) => {
  const title = page?.title?.rendered;

  return (
    <div data-component="text">
      {!isPage && <div className="text" dangerouslySetInnerHTML={{ __html: text }} />}

      {isPage && (
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="text-paper" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      )}
    </div>
  );
};
