import './ShareIcons.scss';

import * as React from 'react';
import { FunctionComponent } from 'react';

interface ShareIconsComponentProps {
  title: string;
  path: string;
  tags?: any[];
}

export const ShareIcons: FunctionComponent<ShareIconsComponentProps> = ({ title, path, tags }) => {
  return (
    <>
      <a
        href={`https://www.facebook.com/sharer.php?u=https://platformonline.uk${path}`}
        className="share-item fab fa-facebook-f"
        style={{ backgroundColor: '#3b5998' }}
        target="_blank"
      />

      <a
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20https://platformonline.uk${path}`}
        className="share-item fab fa-whatsapp"
        data-action="share/whatsapp/share"
        style={{ backgroundColor: '#128c7e' }}
        target="_blank"
      />

      {/*<a*/}
      {/*  href={`https://reddit.com/submit?url=https://platformonline.uk${path}&title=${title}`}*/}
      {/*  className="share-item fab fa-reddit-alien"*/}
      {/*  style={{ backgroundColor: '#ff4500' }}*/}
      {/*  target="_blank"*/}
      {/*/>*/}

      <a
        href={`https://twitter.com/intent/tweet?url=https://platformonline.uk${path}&text=${title}&via=YourPlatformUK`}
        className="share-item fab fa-twitter"
        style={{ backgroundColor: '#1da1f2' }}
        target="_blank"
      />

      {/*<a*/}
      {/*  href={`http://pinterest.com/pin/create/link/?url=https://platformonline.uk${path}`}*/}
      {/*  className="share-item fab fa-pinterest"*/}
      {/*  style={{ backgroundColor: '#bd091a' }}*/}
      {/*  target="_blank"*/}
      {/*/>*/}

      <a
        href={`mailto:?subject=${title}&body=https://platformonline.uk${path}`}
        className="share-item fal fa-envelope"
        style={{ backgroundColor: '#323232' }}
      />
    </>
  );
};
