import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import Link from 'next/link';

import { GET_ARTICLES } from 'Queries';
import { generalOptionsAtom, isSidebarVisibleAtom, toggleSidebarVisibleAtom } from 'Atoms';

import './Sidebar.scss';

export const Sidebar: FC<any> = () => {
  const { menuPageLinks, menuCategories } = useAtomValue(generalOptionsAtom);
  const isSidebarVisible = useAtomValue(isSidebarVisibleAtom);
  const toggleSidebar = useUpdateAtom(toggleSidebarVisibleAtom);

  const { data } = useQuery(GET_ARTICLES, { variables: { perPage: Number(3) } });
  const articles = data?.articles ?? [];

  return (
    <div data-component="sidebar" data-active={isSidebarVisible}>
      <div className="sidebar-background" onClick={toggleSidebar} />
      <div className="sidebar" onClick={toggleSidebar}>
        <div className="sidebar-x">
          <i className="fal fa-times" />
        </div>

        <div className="menu-group">
          <div className="group-title">The Platform</div>
          <div className="group-links">
            {menuPageLinks.map((page, index) => (
              <Link href={`/${page?.post_name}`} key={index}>
                <a className="group-link" dangerouslySetInnerHTML={{ __html: page?.post_title }} />
              </Link>
            ))}
          </div>
        </div>

        <div className="menu-group">
          <div className="group-title">Latest</div>
          <div className="group-links">
            {articles.map((article, index) => (
              <Link href={`/posts/${article.slug}`} key={article.slug}>
                <a className="group-link" dangerouslySetInnerHTML={{ __html: article.title }} />
              </Link>
            ))}
          </div>
        </div>

        <div className="menu-group">
          <div className="group-title">Categories</div>
          <div className="group-links">
            {menuCategories.map((category, index) => (
              <Link href={`/category/${category.slug}`} key={index}>
                <a className="group-link" dangerouslySetInnerHTML={{ __html: category.name }} />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
