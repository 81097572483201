import React from 'react';
import { FC, ReactElement } from 'react';

import { Text, Randoms, Image, Quote, Hero, Hashes, Latest, Feature, Video, Newsletter, Promos } from 'Components';

type ComponentElementProps = {
  component: any;
  page?: any;
  index: number;
};

const ComponentElement: FC<ComponentElementProps> = ({ component, page, index }: ComponentElementProps) => {
  // return null;
  const components: Record<string, ReactElement> = {
    QuoteComponent: <Quote {...component} key={index} />,
    HeroComponent: <Hero {...component} key={index} />,
    LatestPostsComponent: <Latest {...component} key={index} />,
    TagComponent: <Hashes {...component} key={index} />,
    SelectedArticlesComponent: <Promos {...component} key={index} />,
    RandomArticlesComponent: <Randoms {...component} key={index} />,
    FeatureComponent: <Feature {...component} key={index} />,
    NewsletterComponent: <Newsletter {...component} key={index} />,
    VideoComponent: <Video {...component} key={index} />,
    // InstagramComponent: <InstagramComponent  {...component} key={index}/>,
    ImageComponent: <Image {...component} isPage={!!page} page={page} />,
    TextComponent: <Text {...component} isPage={!!page} page={page} key={index} />,
  };

  if (!page) return components[component.acf_fc_layout] ?? null;

  const { backgroundColor, topPadding, bottomPadding } = component?.options ?? {};

  return (
    <div
      className={`section ${backgroundColor}`}
      data-component-type={component['acf_fc_layout']}
      data-top-padding={topPadding}
      data-bottom-padding={bottomPadding}
      key={index}
    >
      <div className="container">{components[component['acf_fc_layout']]}</div>
    </div>
  );
};

interface RenderProps {
  components: Array<any>;
  page?: any;
}

export const Render: FC<RenderProps> = ({ components = [], page }) => {
  // console.log('Render', { components });

  if (!components?.length) return null;

  return (
    <>
      {components.map((component: any, index: number) => (
        <ComponentElement component={component} page={page} index={index} key={index} />
      ))}
    </>
  );
};
