import './Image.scss';

import React, { FC } from 'react';
import { ImageComponent } from 'types';

interface ImageProps {
  isPage?: boolean;
}

export const Image: FC<ImageComponent & ImageProps> = ({ image, caption, isPage }) => {
  const url = image?.url;

  return (
    <div data-component="image">
      {!isPage && (
        <div className="image-box">
          <div className="image">
            {caption && <div className="image-caption" dangerouslySetInnerHTML={{ __html: caption }} />}

            <img src={url} alt={caption} />
          </div>
        </div>
      )}

      {isPage && (
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="normal-image-box">
              <div className="image">
                {caption && <div className="image-caption" dangerouslySetInnerHTML={{ __html: caption }} />}

                <div className="image-element" style={{ backgroundImage: `url("${url}")` }} />
                {/*<img src={url} alt={caption}/>*/}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
