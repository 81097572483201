import { useAtomValue } from 'jotai/utils';
import Link from 'next/link';
import React, { FC } from 'react';

import { generalOptionsAtom } from 'Atoms';

import './Footer.scss';

export const Footer: FC = () => {
  const { footerLinks } = useAtomValue(generalOptionsAtom);

  return (
    <div data-component="footer">
      <div className="container">
        <div className="footer">
          <a className="footer-logo" href="/">
            <img src="/static/img/platform-corner-logo-white.png" alt="" />
          </a>

          <div className="footer-links">
            {footerLinks.map(link => (
              <Link href={link.url} key={link.url}>
                <a className="footer-link" dangerouslySetInnerHTML={{ __html: link.label }} />
              </Link>
            ))}
          </div>

          <div className="footer-copyright">
            © 2020 The Platform. All rights reserved. Read our <a href={'/privacy-policy'}>Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  );
};
