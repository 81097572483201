import * as _ from 'lodash';

// import ArticleData from 'classes/article-data.class';
// import Style from 'interfaces/style.interface';

interface Style {
  backgroundImage?: string;
}

export const GetPostImageUrl = (post: any): string => {
  return _.get(post, `_embedded["wp:featuredmedia"][0].source_url`) || '/static/img/logo.png';
};

export const GetPostImageStyle = (post: any): Style => {
  return { backgroundImage: `url("${GetPostImageUrl(post)}")` };
};

export const Backgroundify = (image = '/static/img/logo.png'): Style => {
  // console.log('Backgroundify', image);
  if (!image) image = '/static/img/logo.png';
  return { backgroundImage: `url("${image}")` };
};

export const NormalizeComponents = (components: string): Array<any> => {
  if (!components) return [];

  return JSON.parse(components) as Array<any>;
};

// export const FormatArticleData = (article: any = {}): ArticleData => {
//   let {date, summary, title, author, content, slug, excerpt}: ArticleData = article || {};
//
//   let featuredImage = _.get(article, 'featuredImage.url');
//   let components = NormalizeComponents(article.components);
//   let categories = article.categories || [];
//   let tags = article.tags || [];
//
//   return {date, summary, title, excerpt, author, content, components, categories, tags, featuredImage, slug};
// };
