import { gql } from '@apollo/client';

export const GET_RANDOM_ARTICLES = gql`
  query getRandomArticle($perPage: Int = 3) {
    randomArticles(perPage: $perPage) {
      id
      date
      title
      slug
      type
      featuredImage {
        id
        title
        caption
        url
        largeUrl
      }
      author {
        id
        slug
        name
        profileImage
      }
      tags {
        id
        slug
        name
        description
      }
      categories {
        id
        slug
        name
        description
      }
      content
      excerpt
      summary
    }
  }
`;
