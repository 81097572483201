import { isAtTopAtom } from 'Atoms';
import { useUpdateAtom } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import ReactGA from 'react-ga';

const useAnalytics = () => {
  const setAtTop = useUpdateAtom(isAtTopAtom);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      ReactGA.pageview(url);
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    ReactGA.initialize('UA-55566804-2');
  }, []);

  useEffect(() => {
    setAtTop(true);
  }, [router.asPath]);
};

export { useAnalytics };
